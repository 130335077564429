    @keyframes example {
    from {
        visibility: hidden;
        opacity: 0;
        top: -30px;
    }
    to {
        visibility: visible;
        opacity: 1;
        top: 0;
    }
  }

.mainClassHero{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;

}

@media screen and (min-width: 480px) {
    .mainClassHero{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0% 10% 0% 9%;
        height: 90vh;
        min-height: 450px;
        max-height: 700px;

    }
  }

  @media screen and (min-width: 1024px) {
    .mainClassHero{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0% 10% 0% 9%;
        height: 90vh;
        min-height: 500px;
        max-height: 700px;

    }
  }


.imageHero{
    height: 50vh;
    margin-bottom: 5vh;
}

@media screen and (min-width: 1024px) {
    .imageHero {
        height: auto;
        width: 80%;
        /* margin-top: 15vh; */
        margin-bottom: 0px;
        margin-left: 40px;
    }
  }

.textContentHero{
    display: flex;
    flex-direction: column;
    padding: 60px 20px 20px 20px;
    margin-left: 0px;
    /* background: #f9f9f9; */
}

@media screen and (min-width: 1024px) {
    .textContentHero{
        display: flex;
        flex-direction: column;
        /* margin-left: 7%; */
        min-width: 45%;
        padding: 0px 0px;
        margin-top: 0px;
        background: transparent;
    }
}

.heroMainImageBackground{
    /* background: linear-gradient(55deg, #f9f9f9 50%, #890b38 50%, #890b38 60%, #f9f9f9 60%, #f9f9f9 70%, #890b38 70%, #890b38 80%, #f9f9f9 80%); */
    /* background: #f9f9f9; */
    /* width: 100%; */
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 480px) {
    .heroMainImageBackground{
        background: transparent;
        width: 38%;
    }
}


.heroLineSoftwareEngineer{
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    color: #0a0a14;
}

@media screen and (min-width: 480px) {
    .heroLineSoftwareEngineer{
        font-size: 20px;
    }
}

.heroHiIcon{
    height: 118px;
    width: 114px;
    margin-bottom: 20px;
}

@media screen and (min-width: 480px) {
    .heroHiIcon{
        
    }
}

.heroHelloIam{
    font-family: inherit;
    margin-top: 0px;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0px;
    text-align: center;

    animation-delay: 0.5s;
    animation-name: example;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    visibility: hidden;
    opacity: 1;
    position: relative;
    margin-bottom: 0px;
}

.companyName{
    font-family: inherit;
    color: #7265E3;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;

}

.heroExplanation{
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    margin: 15px 0px 25px 0px;
    padding: 0px 10px;
}

@media screen and (min-width: 480px) {
    .heroExplanation{
        max-width: 50%;
        margin: 25px 0px 30px 0px;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
    }
}

.myName{

    animation-delay: 0.5s;
    animation-name: example;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    visibility: hidden;
    opacity: 1;
    position: relative;

    color: #7265E3;
    font-family: inherit;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0px;
    text-align: left;
}

.socialMediaIconsContainerHero{
    display: flex;
    margin: 0px 0px;
    justify-content: space-around;
}

.socialMediaIconHero{
height: 16px;
margin: 0px 18px;
}

@media screen and (min-width: 480px) {
    .socialMediaIconHero{
        height: 17px;
        margin: 0px 20px;
        /* margin-top: 20px; */
        }
}

.socialMediaIconHero:hover{
    /* background-color: #890b38; */
    /* background-color: #C3FD66; */
}






/* OLD Logic to To Display moving Circled  background START */

  @keyframes float-up {
    to {
      transform: translateY(-175vh);
    }
  }

    @keyframes float-down {
    to {
      transform: translateY(175vh);
    }
  }

  @keyframes sway-left-to-right {
    from {
      transform: translateX(-100%);
    }
  
    to {
      transform: translateX(100%);
    }
  }
  
  @keyframes sway-right-to-left {
    from {
      transform: translateX(100%);
    }
  
    to {
      transform: translateX(-100%);
    }
  }

  .mainClassHero{
    
        position: relative;
        overflow: hidden;

}
  
  .bubbles {
    position: relative;
    /* width: 100%;
    height: 100vh; */
    overflow: hidden;
  }
  
  .bubble {
    position: absolute;
    left: 15px;
    bottom: -75%;
    display: block;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    animation: float-up 6s 2s ease-in infinite;
  
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(31, 90, 165, 0.35);
      border-radius: inherit;
      animation: 3 7 4 ease-in-out alternate infinite;
    }
  

      /* &:nth-child(0) {
        --bubble-left-offset: 5;
        --bubble-radius: 10;
        --bubble-float-duration: 15;
        --bubble-sway-duration: 7;
        --bubble-float-delay: 2;
        --bubble-sway-delay: 4;
        --bubble-sway-type: 3;
      } */
    
  }

  .bubble2 {
    position: absolute;
    right: 15px;
    bottom: 100%;
    display: block;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    animation: float-down 6s 2s ease-in infinite;
  
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(248, 219, 0, 0.35);
      border-radius: inherit;
      animation: 3 7 4 ease-in-out alternate infinite;
    }
  

      /* &:nth-child(0) {
        --bubble-left-offset: 5;
        --bubble-radius: 10;
        --bubble-float-duration: 15;
        --bubble-sway-duration: 7;
        --bubble-float-delay: 2;
        --bubble-sway-delay: 4;
        --bubble-sway-type: 3;
      } */
    
  }
  /* OLD Logic to To Display moving Circled  background END */






  

/* New Logic to To Display moving background START */
    .mainClassHero1 {
        position: relative;
        z-index: 1;

        &::before {
            content: '';
            /* background: rgba(238, 39, 55, 0.35); */
            background: rgba(31, 90, 165, 0.35);
            filter: blur(108.5px);
            width: 186px;
            height: 186px;
            position: absolute;
            top: -208px;
            left: 7%;
            z-index: -1;
            -webkit-animation: hero_before_rotate 10s infinite linear;
            -moz-animation: hero_before_rotate 10s infinite linear;
            animation: hero_before_rotate 10s infinite linear;

            @include breakpoint-down(md) {
                -webkit-animation: none;
                -moz-animation: none;
                animation: none;
                width: 120px;
                height: 120px;
                background: rgba(238, 39, 55, 0.3);
                filter: blur(75px);
                top: -128px;
                left: 56px;
            }
        }

        &::after {
            content: '';
            /* background: rgba(13, 34, 52, 0.35); */
            background: rgba(248, 219, 0, 0.35);
            filter: blur(108.5px);
            width: 186px;
            height: 186px;
            position: absolute;
            top: 70px;
            right: -50px;
            z-index: -1;
            -webkit-animation: hero_after_rotate 10s infinite linear;
            -moz-animation: hero_after_rotate 10s infinite linear;
            animation: hero_after_rotate 10s infinite linear;

            @include breakpoint-down(md) {
                -webkit-animation: none;
                -moz-animation: none;
                animation: none;
                width: 120px;
                height: 120px;
                background: rgba(13, 34, 52, 0.25);
                filter: blur(60px);
                top: 234px;
                right: 24px;
            }
        }
    }
    



@keyframes hero_before_rotate {
  0% {
      top: -208px;
      left: 7%;
  }

  30% {
      top: calc(100% - 210px);
      left: 27%;
  }

  33% {
      top: calc(100% - 210px);
      left: 27%;
  }

  63% {
      top: 0px;
      left: calc(100% - 186px);
  }

  66% {
      top: 0px;
      left: calc(100% - 186px);
  }

  97% {
      top: -208px;
      left: 7%;
  }

  100% {
      top: -208px;
      left: 7%;
  }
}

@keyframes hero_after_rotate {
  0% {
      top: 70px;
      right: -50px;
  }

  30% {
      top: -200px;
      right: 25%;
  }

  33% {
      top: -200px;
      right: 25%;
  }

  63% {
      top: calc(100% - 206px);
      right: calc(100% - 186px);
  }

  66% {
      top: calc(100% - 206px);
      right: calc(100% - 186px);
  }

  97% {
      top: 70px;
      right: -50px;
  }

  100% {
      top: 70px;
      right: -50px;
  }
}

/* New Logic to To Display moving background END */



  
