
.mainClassFooter{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background: #f9f9f9; */
    /* height: 50vh; */

}

@media screen and (min-width: 480px) {
    .mainClassFooter{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        /* height: 60vh;
        min-height: 300px;
        max-height: 500px; */
        /* background: linear-gradient(55deg, #f9f9f9 50%, #890b38 50%, #890b38 60%, #f9f9f9 60%, #f9f9f9 70%, #890b38 70%, #890b38 80%, #f9f9f9 80%); */

    }
  }

.textContentFooter{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 30px 20px 20px 20px;  by Ali Sarib only for SLIDER for Mobile*/ 
    margin-left: 0px;
    background: #f9f9f9;
    width: 100%;
}

@media screen and (min-width: 480px) {
    .textContentFooter{
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        min-width: 400px;
        padding: 0px 0px;
        margin-top: 0px;
        background: transparent;
    }
}

.sayHelloButtonFooter{
    background-color: #e6a800;
    min-height: 50px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    padding: 0px 40px;
    border-radius: 8px;
    letter-spacing: 1.5px;
}

.sayHelloButtonFooter:hover {
    background-color: #cc9600;
}

.socialMediaLinksHeading{
    color: #0a0a14;
    font-weight: 800;
    letter-spacing: -1px;
    font-family: "Noe Display", serif;
}

.socialMediaIconsContainer{
    display: flex;
    margin: 0px 0px;
    justify-content: space-around;
}

.socialMediaIcon{
height: 32px;
margin: 0px 10px;
}

@media screen and (min-width: 480px) {
    .socialMediaIcon{
        height: 32px;
        margin: 0px 10px;
        /* margin-top: 20px; */
        }
}

.socialMediaIcon:hover{
    /* background-color: #890b38; */
    background-color: #C3FD66;
}

.sectionFooterWelcomeParagraph{
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 12px;
    color: brown;
    padding: 10px 0px;
}

@media screen and (min-width: 480px) {
    .sectionFooterWelcomeParagraph{
        font-size: 18px;
        max-width: 400px;
        line-height: 24px;
        padding: 20px 0px;
    }
}

.sectionFooterDesignedParagraph{
    display: none;
}

@media screen and (min-width: 480px) {
    .sectionFooterDesignedParagraph{
        display: block;
        font-size: 18px;
        max-width: 400px;
        line-height: 24px;
        padding: 30px 0px;
        font-weight: 600;
        margin-bottom: 12px;
        color: #2f89c5;
    }
}

.sectionFooterDesignedParagraphMobile{
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
    color: #2f89c5;
    background-color: #f9f9f9;
    padding: 20px 0px;   
}

@media screen and (min-width: 480px) {
    .sectionFooterDesignedParagraphMobile{
        display: none;
    }
}

.sectionFooterDesignedByAli{
    color: #890b38;
    font-weight: 700;
}




.footerMailLink{
    color: #7265E3;
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
  }

  .footerMailLink:hover {
    text-decoration: none;
    cursor: pointer;
    color: #5b51b6	;
    font-weight: 600;
}

.neuroMobileSectionFooterLearningMainParagraph{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
}

.mySliderClass{
    background-color: #C3FD66;
    height: 100px;
    display: flex;
    align-items: center;

    font-family: 'Calibre';
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    padding-top: 10px;
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000;

    /* max-width: 330px; */
}

@media screen and (min-width: 950px) {
    .mySliderClass{    
        /* max-width: 1450px; */
        font-weight: 600;
    }
}

.textContentFooterLastLine{
    display: flex;
    flex-direction: column;
align-items: center;
    margin: 60px 0px;
    width: 100%;
}

@media screen and (min-width: 950px) {
    .textContentFooterLastLine{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 100px;
        margin: 70px 0px;
        width: 100%;
        /* min-width: 1300px; */
        max-width: 1450px;
    }
}

.sectionFooterAllRightsParagraph{
    font-family: 'Calibre';
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #909090;
}

.sectionFooterUpParagraph{
    font-family: 'Calibre';
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 5px;
    cursor: pointer;
}

.cpyIcon{
    height: 15px;
    margin-right: 4px;
    }
    
    @media screen and (min-width: 950px) {
        .cpyIcon{
            height: 15px;
            }
    }

    .upIcon{
        height: 28px;
        margin-left: 12px;
        cursor: pointer;
        }
        
        @media screen and (min-width: 950px) {
            .upIcon{
                height: 28px;
                }
        }

.cpyDivContainer{
display: none;

}

@media screen and (min-width: 950px) {
    .cpyDivContainer{
        display: flex;
        margin-top: 20px;
        
        }
}

.upDivContainer{
    display: flex;
    margin-top: 18px;
    
    }

.cpyDivContainerMobile{
    display: flex;
    margin: 20px 0px;
    
    }

    @media screen and (min-width: 950px) {
        .cpyDivContainerMobile{
            display: none;
            
            }
    }
