.mainClassTestNavBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    padding: 0px 20px;
    height: 90px;
}

.topbarRightContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}

.helpText{
    color: white;
    margin: 10px 20px 10px 20px;
}

.topbarImage{
    width: 150px;
    height: 50px;
}

.twitterLogo{
    width: 50px;
}

.signInButton{
    background-color: #339aff;
    color: white;
    border-radius: 5px;
    width: 90px;
    height: 40px;
    border: none;
}