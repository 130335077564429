.mainClassBagationHero{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; 
    background: rgba(68, 197, 228, 0.1);


}

@media screen and (min-width: 1024px) {
    .mainClassBagationHero{
        background: rgba(68, 197, 228, 0.1);
    }
  }


  .imageHeroValiant{
    /* height: 30vh; */
    width: 90%;
    margin-bottom: 5vh;
    display: none;
}

@media screen and (min-width: 1024px) {
    .imageHeroValiant {
        display: block;
        height: 500px;
        width: auto;
        /* width: 80%; */

        /* margin-top: 15vh; */
        margin-bottom: 100px;
        margin-top: 100px;
        margin-left: 40px;
    }
  }

  .imageHeroValiantMobile{
    /* height: 30vh; */
    width: 90%;
    margin-bottom: 5vh;
}

@media screen and (min-width: 1024px) {
    .imageHeroValiantMobile {

        display: none;
        height: 500px;
        width: auto;
        /* width: 80%; */

        /* margin-top: 15vh; */
        margin-bottom: 100px;
        margin-top: 100px;
        margin-left: 40px;
    }
  }


.valiantSectionHomeImgMain{
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 1024px) {
    .valiantSectionHomeImgMain{
        flex-direction: row;
        align-items: flex-start;
    }
}

.LogoRACV{
    height: 70px;
    margin: 40px 0px;
}

.racvHeroMainHeading{
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0px;
    max-width: 500px;
    /* text-align: left; */
    }
    
    .racvHeroSubHeading{
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    max-width: 480px;
        }

.racvSectionProblemChallangeParagraph{
    font-family: 'Calibre';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    max-width: 700px;
}

@media screen and (min-width: 1024px) {
    .racvSectionProblemChallangeParagraph{
        font-family: 'Calibre';
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        min-width: 700px;
        max-width: 700px;
    }
}

.bagationSectionInsightsSingleDiv{

    background: rgba(68, 197, 228, 0.2);
    margin: 8px;
    padding: 30px 13px;
    height: 100px;
    width: 102px;
    /* width: 100%; */
    /* border: 1px solid rgba(114, 101, 227, 0.2); */
}

@media screen and (min-width: 1024px) {
    .bagationSectionInsightsSingleDiv{
        margin: 10px;
        padding: 30px;
        height: 175px;
        width: 170px;
    }
}

.racvSectionScopeMainHeading{
    font-family: 'Calibre';
    font-size: 23px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    margin: 25px 0px;
}

.racvSectionTheSolutionVideo1{
    /* width: 100%;
    height: auto;
    margin-bottom: 20px; */

    height: 50px;
    align-self: flex-start;
    margin: 20px 0px 15px 0px;
}

@media screen and (min-width: 768px) {
    .racvSectionTheSolutionVideo1{
        width: auto;
        height: 60px;
        margin-bottom: 0px;
    }
}
