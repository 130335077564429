
.mainClassLatestWork{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: #F1F0FC; */
    /* height: 50vh; */

}

@media screen and (min-width: 480px) {
    .mainClassLatestWork{
        display: flex;
        /* flex-direction: row; */
        justify-content: center;
        align-items: center;
        padding: 50px 0px;
        /* height: 60vh;
        min-height: 300px;
        max-height: 500px; */
    }
  }

.textContentLatestWork{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 20px 20px 20px;
    margin-left: 0px;
    margin-bottom: 10px;
    /* background: #f9f9f9; */
}

@media screen and (min-width: 1024px) {
    .textContentLatestWork{
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        min-width: 400px;
        padding: 0px 0px;
        margin-top: 0px;
        margin-bottom: 40px;
        background: transparent;
    }
}

.latestWorkHeading{
    color: #0a0a14;
    font-weight: 800;
    letter-spacing: -1px;
    font-family: "Noe Display", serif;
}

.latestWorkMainParagraph{
    font-weight: 500;
}

.mobilePic1{
    height: 220px;
    margin-right: 30px;
    transition: transform .8s;
    position: relative;
}

@media screen and (min-width: 768px) {
    .mobilePic1{
        height: 400px;
    }
}

.mobilePic1:hover{
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2); 
}

.laptopPic1{
    height: 200px;
    margin-left: 10px;
    transition: transform .8s;
    position: relative;
}

@media screen and (min-width: 768px) {
    .laptopPic1{
        height: 350px;
    }
}

.laptopPic1:hover{
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2); 
}

.Pic2{
    height: 200px;
    transition: transform .8s;
    position: relative;
}

@media screen and (min-width: 768px) {
    .Pic2{
        height: 370px;
    }
}

.Pic2:hover{
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2); 
}

.latestWorkMainContainer{
    display: flex;
    flex-direction: column;

}

@media screen and (min-width: 1024px) {
    .latestWorkMainContainer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    
    }
}

.latestWorkPicContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    /* justify-content: center; */
    min-width: 300px;
    min-height: 350px;
    background: rgba(240, 252, 241, 1);
    cursor: pointer;
}

@media screen and (min-width: 389px) {
    .latestWorkPicContainer{
        min-width: 330px;
        min-height: 370px;
    }
}

@media screen and (min-width: 400px) {
    .latestWorkPicContainer{
        min-width: 370px;
        min-height: 380px;
    }
}

@media screen and (min-width: 768px) {
    .latestWorkPicContainer{
        min-width: 500px;
        min-height: 550px;
    }
}

@media screen and (min-width: 1024px) {
    .latestWorkPicContainer{
        min-width: 500px;
        min-height: 720px;
    }
}

.latestWorkLaptopPicContainer{
    display: flex;
    align-items: flex-end;
    padding-bottom: 50px;
    justify-content: center;
    min-width: 300px;
    min-height: 350px;
    background: #F6F1EC;
}

@media screen and (min-width: 768px) {
    .latestWorkLaptopPicContainer{
        min-width: 500px;
        min-height: 550px;
    }
}

.latestWorkMobilesPicContainer{
    display: flex;
    align-items: flex-end;
    padding-bottom: 50px;
    justify-content: center;
    min-width: 300px;
    min-height: 350px;
    /* background: #e9F5FB; */
    background: rgba(78, 206, 114, 0.1);
}

@media screen and (min-width: 768px) {
    .latestWorkMobilesPicContainer{
        min-width: 500px;
        min-height: 550px;
    }
}

.latestWorkEmptyContainer{
    margin: 0px 20px;
}

.latestWorkEmptyHorizontalContainer{
    margin: 20px 0px;
}

@media screen and (min-width: 1024px) {
    .latestWorkEmptyHorizontalContainer{
        margin: 90px 0px;
    }
}

.latestWorkAppHeading{
    margin-top: 20px;
    color: #0a0a14;
    font-weight: 800;
    /* letter-spacing: -1px; */
    font-family: "Noe-Display";
    font-size: 22px;
    line-height: 30px;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .latestWorkAppHeading{
        font-size: 25px;
    }
}

.latestWorkAppParagraph{
    width: 270px;
    font-weight: 500;
    cursor: pointer;
}

@media screen and (min-width: 389px) {
    .latestWorkAppParagraph{
        width: 300px;
    }
}

@media screen and (min-width: 400px) {
    .latestWorkAppParagraph{
        width: 330px;
    }
}

@media screen and (min-width: 768px) {
    .latestWorkAppParagraph{
        width: 500px;
        font-weight: 500;
        cursor: pointer;
    }
}

.latestWorkAppEnterPassword{
    /* font-family: 'Calibri, serif'; */
    font-weight: 700;
    font-size: 18px;
    /* letter-spacing: -.5px; */
}

.latestWorkAppEnterPassword:hover {
    cursor: pointer;
}

.aero{
    width: 16px;
    height: 10px;
    margin-bottom: 4px;
    margin-left: 10px;
}

.latestWorkAppAeroText{
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

.spaceInModalContent{
    margin: 30px 0px;
}

.modalLock{
    height: 100px;
}

.toView{
    color: #ABABAB;
    font-size: 18px;
    font-weight: 500;
}

.modalMain{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
}

.form-control{
    width: 250px !important;
}

.modal-content{
    border-radius: 1.3rem !important;
}

.modal-dialog{
    margin: 9vh 10px !important;
}

@media screen and (min-width: 480px) {
    .modal-dialog{
        margin: 40% auto !important;
    }
}

@media screen and (min-width: 768px) {
    .modal-dialog{
        margin: 7% auto !important;
    }
}

.btn-primary{
    background-color: #7265E3 !important;
    border-color: #7265E3 !important;
}

.modalContentProtected{
    text-align: center;
}

.latestWorkInsideContainerText{
    padding: 25px;
}

@media screen and (min-width: 480px) {
    .latestWorkInsideContainerText{
        padding: 20px 40px;
    }
}

.latestWorkPicContainer2{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    /* justify-content: center; */
    min-width: 300px;
    min-height: 350px;
    background: rgba(255, 248, 231, 1);
    cursor: pointer;
}

@media screen and (min-width: 389px) {
    .latestWorkPicContainer2{
        min-width: 330px;
        min-height: 370px;
    }
}

@media screen and (min-width: 400px) {
    .latestWorkPicContainer2{
        min-width: 370px;
        min-height: 380px;
    }
}

@media screen and (min-width: 768px) {
    .latestWorkPicContainer2{
        min-width: 500px;
        min-height: 550px;
    }
}

@media screen and (min-width: 1024px) {
    .latestWorkPicContainer2{
        min-width: 500px;
        min-height: 720px;
    }
}

.latestWorkPicContainer3{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    /* justify-content: center; */
    min-width: 300px;
    min-height: 350px;
    background: rgba(233, 238, 246, 1);
    cursor: pointer;
}

@media screen and (min-width: 389px) {
    .latestWorkPicContainer3{
        min-width: 330px;
        min-height: 370px;
    }
}

@media screen and (min-width: 400px) {
    .latestWorkPicContainer3{
        min-width: 370px;
        min-height: 380px;
    }
}

@media screen and (min-width: 768px) {
    .latestWorkPicContainer3{
        min-width: 500px;
        min-height: 550px;
    }
}

@media screen and (min-width: 1024px) {
    .latestWorkPicContainer3{
        min-width: 500px;
        min-height: 720px;
    }
}

.latestWorkPicContainer4{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    /* justify-content: center; */
    min-width: 300px;
    min-height: 350px;
    background: rgba(116, 213, 255, 0.1);
    cursor: pointer;
}

@media screen and (min-width: 389px) {
    .latestWorkPicContainer4{
        min-width: 330px;
        min-height: 370px;
    }
}

@media screen and (min-width: 400px) {
    .latestWorkPicContainer4{
        min-width: 370px;
        min-height: 380px;
    }
}

@media screen and (min-width: 768px) {
    .latestWorkPicContainer4{
        min-width: 500px;
        min-height: 550px;
    }
}

@media screen and (min-width: 1024px) {
    .latestWorkPicContainer4{
        min-width: 500px;
        min-height: 720px;
    }
}





