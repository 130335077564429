
.mainClassAbout{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: transparent;
}

@media screen and (min-width: 480px) {
    .mainClassAbout{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100vh;
        background: linear-gradient(55deg, #f9f9f9 50%, #890b38 50%, #890b38 60%, #f9f9f9 60%, #f9f9f9 70%, #890b38 70%, #890b38 80%, #f9f9f9 80%);

    }
  }

.titleAboutSection{
    font-family: cursive;
    font-size: 46px;
    font-weight: 700;
    color: #2f89c5;
}

.titleAbout{
    color: #890b38;
    font-weight: 700;
    letter-spacing: 2px;   
}

.imageAbout{
    height: 50vh;
}

@media screen and (min-width: 480px) {
    .imageAbout {
        height: 100vh;
    }
  }

.textContentAbout{
    display: flex;
    flex-direction: column;
    padding: 30px 20px 20px 20px;
    margin-left: 0px;
    margin-top: 0px;
    background: #f9f9f9;
}

@media screen and (min-width: 480px) {
    .textContentAbout{
        display: flex;
        flex-direction: column;
        margin-left: 100px;
        max-width: 45%;
        padding: 0px 0px;
        margin-top: 0px;
        background: transparent;
    }
}

.aboutP1{
    line-height: 25px;
    max-width: 100%;
    padding: 0px 15px;
}

@media screen and (min-width: 480px) {
    .aboutP1{
        line-height: 25px;
        max-width: 90%;
    }
}

.aboutMainImageBackground{
    background: linear-gradient(55deg, #f9f9f9 50%, #890b38 50%, #890b38 60%, #f9f9f9 60%, #f9f9f9 70%, #890b38 70%, #890b38 80%, #f9f9f9 80%);
    width: 100%;
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 480px) {
    .aboutMainImageBackground{
        background: transparent;
    }
}
