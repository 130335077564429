.mainRybaContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F6F1EC;
}

.backAeroContainerRyba{
    display: none;
}

@media screen and (min-width: 481px) {
    .backAeroContainerRyba{
        background-color: #F6F1EC;
        display: flex;
        justify-content: center;
        padding: 0px 90px;
    }
}

.rybaSectionInsightsMainDivHeading{
    margin: 20px 0px;
    font-size: 25px;
    font-weight: 600;
    font-family: "Calibre";
    letter-spacing: -.5px;
    /* text-align: center; */
}

@media screen and (min-width: 1024px) {
    .rybaSectionInsightsMainDivHeading{
        letter-spacing: 0px;
        width: 100%;
    }
}

.rybaSectionInsightsMainDivParagraph{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 20px;
    /* text-align: center; */
}

.rybaSectionInsightsSingleDiv{
    background-color:#FFB80C1A;
    margin: 8px;
    padding: 30px 13px;
    height: 100px;
    width: 102px;
    /* width: 100%; */
    /* border: 1px solid rgba(114, 101, 227, 0.2); */
}

@media screen and (min-width: 1024px) {
    .rybaSectionInsightsSingleDiv{
        margin: 10px;
        padding: 30px;
        height: 175px;
        width: 170px;
    }
}

.rybaSectionInsightsDivsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 0px;
}

@media screen and (min-width: 1024px) {
    .rybaSectionInsightsDivsContainer{
        display: flex;
        flex-direction: row;
        margin: 0px 80px;
    }
}

.rybaSectiondesignPrincipalsdiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 1024px) {
    .rybaSectiondesignPrincipalsdiv{
        flex-direction: row;
    }
}

.rybaSectionScopeMainDivImg{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

@media screen and (min-width: 1024px) {
    .rybaSectionScopeMainDivImg{
        flex-direction: row;
    }
}

.rybaSectionScopeImgClass{
    width: 80%;
    height: auto;
    margin: 20px;
}

@media screen and (min-width: 1024px) {
    .rybaSectionScopeImgClass{
        width: auto;
        height: 150px;
        margin-top: 20px;
        margin-left: 30px;
        margin-right: 30px;
    }
}

.rybaSectionScope{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.rybaSectionScopeMainDiv{
    padding: 50px 20px 0px 20px;
}

@media screen and (min-width: 1024px) {
    .rybaSectionScopeMainDiv{
        max-width: 850px;
        padding: 70px 0px 0px 0px;
    }
}

.rybaSectionWireFramesImg{
    width: 100%;
    height: auto;
    margin-top: 20px;
}

@media screen and (min-width: 1024px) {
    .rybaSectionWireFramesImg{
        width: auto;
        height: 400px;
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.rybaSectionHomeMidHeading{
    font-family: 'Calibre';
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    margin-bottom: 0px;
    margin-top: 15px;
    text-align: center;
}

.rybaSectionHighConceptualizationParagraph{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 0px;
    text-align: center;
    color: #4F5963;
}

.rybaSectionHomeImg{
    width: 100%;
    height: auto;
    margin-top: 20px;
}

@media screen and (min-width: 1024px) {
    .rybaSectionHomeImg{
        width: auto;
        height: 980px;
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.rybaSectionHomeMobileImg{
    width: 100%;
    height: auto;
    margin-top: 20px;
}

@media screen and (min-width: 1024px) {
    .rybaSectionHomeMobileImg{
        width: auto;
        height: 1220px;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 10px;
    }
}

.rybaSectionHomeImgMain{
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 1024px) {
    .rybaSectionHomeImgMain{
        flex-direction: row;
    }
}

.rybaSectionBioImg{
    width: 100%;
    height: auto;
    margin-top: 20px;
}

@media screen and (min-width: 1024px) {
    .rybaSectionBioImg{
        width: auto;
        height: 830px;
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.rybaSectionBioMobileImg{
    width: 100%;
    height: auto;
    margin-top: 20px;
}

@media screen and (min-width: 1024px) {
    .rybaSectionBioMobileImg{
        width: auto;
        height: 1115px;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 10px;
    }
}

.viewFullPriject{
    font-family: 'Calibre';
    font-size: 25px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 0px;
    text-align: center;
    color: #7265E3;
    border-bottom: 2px solid #7265E3 ;
}

.viewFullPriject:hover{
text-decoration: none;
cursor: pointer;
color: #7265E3;
}

.neuroMobileSectionOverviewHeadingRyba{
    font-family: 'Calibre';
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionOverviewHeadingRyba{
        margin-top: 78px;
    }
}

.rybaLogoNeuroMobile{
    height: 70px;
    margin-top: 10px;
}

