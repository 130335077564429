.mainClassValiantHero{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; 
    background: rgba(102, 221, 116, 0.1);

}

@media screen and (min-width: 1024px) {
    .mainClassValiantHero{
        background: rgba(102, 221, 116, 0.1);

    }
  }


  .imageHeroValiant{
    /* height: 30vh; */
    width: 90%;
    margin-bottom: 5vh;
    display: none;
}

@media screen and (min-width: 1024px) {
    .imageHeroValiant {
        display: block;
        height: 500px;
        width: auto;
        /* width: 80%; */

        /* margin-top: 15vh; */
        margin-bottom: 100px;
        margin-top: 100px;
        margin-left: 40px;
    }
  }

  .imageHeroValiantMobile{
    /* height: 30vh; */
    width: 90%;
    margin-bottom: 5vh;
}

@media screen and (min-width: 1024px) {
    .imageHeroValiantMobile {

        display: none;
        height: 500px;
        width: auto;
        /* width: 80%; */

        /* margin-top: 15vh; */
        margin-bottom: 100px;
        margin-top: 100px;
        margin-left: 40px;
    }
  }

  .valiantSectionInsightsSingleDiv{

    background: rgba(102, 221, 116, 0.2);
    margin: 8px;
    padding: 30px 13px;
    height: 100px;
    width: 102px;
    /* width: 100%; */
    /* border: 1px solid rgba(114, 101, 227, 0.2); */
}

@media screen and (min-width: 1024px) {
    .valiantSectionInsightsSingleDiv{
        margin: 10px;
        padding: 30px;
        height: 175px;
        width: 170px;
    }
}

.valiantSectionHomeImgMain{
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 1024px) {
    .valiantSectionHomeImgMain{
        flex-direction: row;
        align-items: flex-start;
    }
}