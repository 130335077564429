
.mainClassNeuroMobileWebsite{
    margin: auto;
    /* max-width: 1460px; */
}

.mainNeuroMobileContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F1F0FC;
}

.neuroMobileAppHeading{
    margin-top: 50px;
    color: #0a0a14;
    font-weight: 800;
    font-family: "Noe-Display";
    font-size: 22px;
    line-height: 30px;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .neuroMobileAppHeading{
        font-size: 40px;
    }
}

.neuroMobileAppParagraph{
    width: 300px;
    margin: 30px 0px 50px 0px;
    font-weight: 500;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .neuroMobileAppParagraph{
        margin: 30px 0px 90px 0px;
        width: 653px;
        font-size: 19px;
        font-weight: 500;
        cursor: pointer;
    }
}

.threeMobiles{
    height: auto;
    width: 90%;
}

@media screen and (min-width: 768px) {
    .threeMobiles{
        height: 300px;
        width: auto;
    }
}

@media screen and (min-width: 1024px) {
    .threeMobiles{
        height: 500px;
        width: auto;
    }
}

.neuroMobileSectionOverview{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 0px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionOverview{
        flex-direction: row;
        padding: 100px 0px;
    }
}

.neuroMobileSectionOverviewDivOne{
    margin: 0px 20px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionOverviewDivOne{
        max-width: 400px;
        margin: 0px 150px 0px 0px;
    }
}

.neuroMobileSectionOverviewDivOneReact{
    color: #7265E3;
    font-weight: 600;
}

.reactLogoNeuroMobile{
    height: 70px;
}

.neuroMobileSectionOverviewDivTwo{
    margin: 30px 20px 0px 20px;   
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionOverviewDivTwo{
        margin: 0px 0px;   
    }
}

.neuroMobileSectionTheProblem{
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
}

.neuroMobileSectionTheProblemMainDiv{
    padding: 50px 20px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionTheProblemMainDiv{
        max-width: 850px;
        padding: 70px 0px;
    }
}

.neuroMobileSectionResearch{
    display: flex;
    justify-content: center;
}

.neuroMobileSectionResearchMainDiv{
    padding: 50px 20px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionResearchMainDiv{
        max-width: 850px;
        padding: 70px 0px;
    }
}

.neuroMobileSectionProjectLearningMainDiv{
    padding: 50px 20px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionProjectLearningMainDiv{
        max-width: 750px;
        padding: 70px 0px;
    }
}

.neuroMobileSectionInsightsMainDiv{
    padding: 30px 20px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionInsightsMainDiv{
        max-width: 850px;
        padding: 50px 20px 0px 20px;
    }
}

.researchPicNeuroMobile{
    width: 100%;
    height: auto;
    margin-top: 20px;
}

.neuroMobileSectionInsights{
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.neuroMobileSectionUserProfiles{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.neuroMobileSectionInsightsDivsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 0px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionInsightsDivsContainer{
        display: flex;
        flex-direction: row;
        margin: 0px 80px;
    }
}

.resultsPicNeuroMobile{
    height: 40px;
    width: auto;
    margin-bottom: 15px;
}

.neuroMobileSectionInsightsSingleDiv{
    background-color: #ffffff;
    margin: 20px;
    padding: 30px;
    border: 1px solid rgba(114, 101, 227, 0.2);
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionInsightsSingleDiv{
        background-color: #ffffff;
        margin: 20px;
        padding: 30px;
        border: 1px solid rgba(114, 101, 227, 0.2);
        height: 300px;
    }
}

.neuroMobileSectionUserProfilesDivsContainerSpec{
    background-color: #ffffff;
    margin: 20px 0px;
    padding: 30px;
    border: 1px solid rgba(114, 101, 227, 0.2);
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionUserProfilesDivsContainerSpec{
 
        background-color: #ffffff;
        margin: 20px 0px;
        padding: 30px;
        border: 1px solid rgba(114, 101, 227, 0.2);
    }
}

.neuroMobileSectionUserProfilesDivsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 0px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionUserProfilesDivsContainer{
        margin: 0px 80px;
    }
}

.purpleHeadingNeuroMobile{
    font-family: 'Calibre';
    color: #7265E3;
    font-size: 24px;
    font-weight: 660;
}

.neuroMobileSectionResearchMainDivHeading{
    font-family: 'Calibre';
    font-size: 23px;
    font-weight: 600;
    line-height: 16px;
}

.neuroMobileSectionConceptualizationSubDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.neuroMobileSectionConceptualizationHeading{
    font-family: 'Calibre';
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    margin-bottom: 0px;
}

.neuroMobileSectionMoreProjectsHeading{
    font-family: 'Calibre';
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    margin-bottom: 0px;
    text-align: center;
    padding: 20px;
}

.neuroMobileSectionConceptualizationParagraph{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 0px;
    text-align: center;
}

.neuroMobileSectionConceptualizationMainParagraph{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 20px;
}

.neuroMobileSectionProjectLearningMainParagraph{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 30px;
}

.neuroMobileSectionUsersProfilesParagraphs{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
}

.neuroMobileSectionUsersProfilesParagraphsBold{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
}

.neuroMobileSectionUsersProfilesParagraphCustom{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
}

.neuroMobileSectionUsersProfilesHeadings{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
}

.neuroMobileSectionOverviewHeading{
    font-family: 'Calibre';
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

.neuroMobileSectionOverviewParagraphs{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
}

.neuroMobileSectionOverviewCustomParagraph{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    max-width: 300px;
}

.neuroMobileSectionProjectLearningHeadings{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
}

.reactNeuroMobilesPicContainer{
    display: flex;
    align-items: flex-end;
    padding-bottom: 50px;
    margin-bottom: 0px;
    justify-content: center;
    min-width: 300px;
    min-height: 350px;
    /* background: #e9F5FB; */
    background: rgba(78, 206, 114, 0.1);
}

@media screen and (min-width: 768px) {
    .reactNeuroMobilesPicContainer{
        min-width: 350px;
        min-height: 370px;
        margin: 0px 20px;
    }
}

.reactNeuroPicContainer{
    display: flex;
    align-items: flex-end;
    padding-bottom: 50px;
    margin: 15px 0px 0px 0px;
    justify-content: center;
    min-width: 300px;
    min-height: 350px;
    background: #F1F0FC;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .reactNeuroPicContainer{
        min-width: 350px;
        min-height: 370px;
        margin: 0px 20px;
    }
}

.reactNeuroLaptopPicContainer{
    display: flex;
    align-items: flex-end;
    padding-bottom: 50px;
    margin: 15px 0px 0px 0px;
    justify-content: center;
    min-width: 300px;
    min-height: 350px;
    background: #F6F1EC;
}

@media screen and (min-width: 768px) {
    .reactNeuroLaptopPicContainer{
        min-width: 350px;
        min-height: 370px;
        margin: 0px 20px;
    }
}

.laptopPic1NeuroMobile{
    height: 200px;
    margin-left: 10px;
    transition: transform .8s;
    position: relative;
}

@media screen and (min-width: 768px) {
    .laptopPic1NeuroMobile{
        height: 230px;
    }
}

.laptopPic1NeuroMobile:hover{
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2); 
}

.Pic2NeuroMobile{
    height: 200px;
    transition: transform .8s;
    position: relative;
}

@media screen and (min-width: 768px) {
    .Pic2NeuroMobile{
        height: 230px;
    }
}

.Pic2NeuroMobile:hover{
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2); 
}

.mobilePic1NeuroMobile{
    height: 220px;
    margin-right: 30px;
    transition: transform .8s;
    position: relative;
}

@media screen and (min-width: 768px) {
    .mobilePic1NeuroMobile{
        height: 250px;
    }
}

.mobilePic1NeuroMobile:hover{
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2); 
}

.neuroMobileSectionTheSolutionVideo{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .neuroMobileSectionTheSolutionVideo{
        width: auto;
        height: 400px;
        margin-bottom: 0px;
    }
}

.neuroMobileSectionTheSolutionVideo1{
    /* width: 100%;
    height: auto;
    margin-bottom: 20px; */

    height: 50px;
    align-self: flex-start;
    margin: 20px 0px 15px 0px;
}

@media screen and (min-width: 768px) {
    .neuroMobileSectionTheSolutionVideo1{
        width: auto;
        height: 70px;
        margin-bottom: 0px;
    }
}

.neuroMobileSectionTheSolutionVideoAndParagraphDiv{
    display: flex;
    flex-direction: column;
    margin: 40px 0px 0px 0px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionTheSolutionVideoAndParagraphDiv{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 70px 0px 0px 0px;
    }
}

.neuroMobileSectionTheSolutionSubDiv{
max-width: 400px;
}

.neuroMobileSectionTheSolutionSubDiv1{
    max-width: 700px;
    }

.neuroMobileSpacer{
    height: 100px;
}

.leftAero{
    width: 32px;
    height: 19px;
    margin-bottom: 4px;
    margin: 40px 0px 20px 50px;
}

@media screen and (min-width: 481px) {
    .leftAero{
        width: 42px;
        height: 27px;
        margin-bottom: 4px;
        margin: 40px 0px 20px 0px;
    }
}

.backAeroContainer{
    display: none;
}

@media screen and (min-width: 481px) {
    .backAeroContainer{
        background-color: #F1F0FC;
        display: flex;
        justify-content: center;
        padding: 0px 90px;
    }
}

.neuroMobileSectionInsightsMainContainer{
    max-width: 1460px;
    padding-bottom: 30px;
}

.backAeroContainerSub{
width: 100%;
max-width: 1280px;
}


/* -------------------------------- */
/* New Code 2023 for Jay's Website  */
/* -------------------------------- */

.mainClassBetfairHero{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 184, 12, 0.1);
}

@media screen and (min-width: 1024px) {
    .mainClassBetfairHero{
        background: rgba(255, 184, 12, 0.1);

    }
  }

.leftClassBetfairHero{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;

}

@media screen and (min-width: 1024px) {
    .leftClassBetfairHero{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 80px;
    
    }
  }

.innerleftClassBetfairHero{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

}


.heroMainImageBackgroundBetfair{
    /* background: linear-gradient(55deg, #f9f9f9 50%, #890b38 50%, #890b38 60%, #f9f9f9 60%, #f9f9f9 70%, #890b38 70%, #890b38 80%, #f9f9f9 80%); */
    /* background: #f9f9f9; */
    /* width: 100%; */
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 480px) {
    .heroMainImageBackgroundBetfair{
        /* background: rgba(255, 184, 12, 0.1); */
        /* width: 60%; */
        /* height: 100%; */
        align-items: center;
        justify-content: center;
        /* border-radius: 100px 0px 0px 100px; */
        /* transform-origin: left;
        transform: translateX(-1%) scale(2); */
        
    }
}

.imageHeroBetfair{
    /* height: 30vh; */
    width: 90%;
    margin-bottom: 5vh;
}

@media screen and (min-width: 1024px) {
    .imageHeroBetfair {
        height: 500px;
        width: auto;
        /* width: 80%; */

        /* margin-top: 15vh; */
        margin-bottom: 100px;
        margin-top: 100px;
        margin-left: 40px;
    }
  }

  .subClassBetfairHero{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (min-width: 1024px) {
    .subClassBetfairHero{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        /* margin: 0% 10% 0% 9%; */

        /* height: 100vh; */
        width: 100%;
        max-width: 1350px;
        /* min-height: 500px; */
        /* max-height: 700px; */
      }
  }

  .backAeroContainerBetfairHero{
    display: none;
}

@media screen and (min-width: 481px) {
    .backAeroContainerBetfairHero{
        display: flex;
        justify-content: center;
        /* padding: 0px 90px; */
    }
}

.backAeroContainerBetfairHeroSub{
    width: 100%;
    max-width: 1280px;
}

.betfairHeroMainHeading{
font-size: 24px;
font-weight: 500;
line-height: 36px;
letter-spacing: 0px;
max-width: 400px;
/* text-align: left; */
}

.betfairHeroSubHeading{
font-size: 18px;
font-weight: 500;
line-height: 29px;
letter-spacing: 0em;
max-width: 380px;
    }

.LogoBetfair{
    height: 40px;
    margin: 40px 0px;
}

.threeButtons{
    width: 100%;
    margin: 40px 0px;
}

@media screen and (min-width: 1024px) {
    .threeButtons{
        height: 50px;
        width: auto;
        margin: 40px 0px;
    }
}

.betfairSectionOverviewHeading{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 10px;
}

@media screen and (min-width: 1024px) {
    .betfairSectionOverviewHeading{
        font-family: 'Calibre';
        font-size: 18px;
        font-weight: 600;
        line-height: 16px;
        margin-bottom: 20px;
    }
}

.betfairSectionOverviewDivOne{
    margin: 0px 20px;
}

@media screen and (min-width: 1024px) {
    .betfairSectionOverviewDivOne{
        max-width: 215px;
        margin: 0px 0px 0px 0px;
    }
}

.betfairSectionOverview{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 100px 0px 50px 0px;
}

@media screen and (min-width: 768px) {
    .betfairSectionOverview{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 100px 0px 50px 0px;
    }
}

@media screen and (min-width: 1024px) {
    .betfairSectionOverview{
        flex-direction: row;
        justify-content: space-between;
        min-width: 900px;
        max-width: 1300px;
        margin: 0px 100px;
        padding: 100px 0px;
    }
}

.betfairSectionOverviewMain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.betfairSectionOverviewCustomParagraph{
    font-family: 'Calibre';
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    /* max-width: 180px; */
}

.betfairSectionProblemChallange{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 15px;
}

@media screen and (min-width: 1024px) {
    .betfairSectionProblemChallange{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 60px 0px;
    }
}

@media screen and (min-width: 1024px) {
    .betfairSectionProblemChallange{
        justify-content: space-between;
        width: 100%;
        max-width: 1300px;
        padding: 80px 40px;
    }
}

.betfairSectionProblemChallangeDivOne{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (min-width: 1024px) {
    .betfairSectionProblemChallangeDivOne{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        /* width: 100%; */

    }
}

.ProblemChallangeIcons{
    height: 40px;
    align-self: flex-start;
    margin: 20px 0px 10px 0px;
    /* width: 60%; */
}

@media screen and (min-width: 1024px) {
    .ProblemChallangeIcons{
        height: 40x;
        width: auto;
        margin: 40px 90px 40px 0px;
    }
}


.betfairSectionProblemChallangeParagraph{
    font-family: 'Calibre';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    max-width: 700px;
}

.betfairSectionScope{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 0px;
}

@media screen and (min-width: 1024px) {
    .betfairSectionScope{
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        max-width: 1300px;
        padding: 100px 0px;
    }
}

.ScopeIcons{
    margin: 20px 0px 20px 0px;
}

@media screen and (min-width: 768px) {
    .ScopeIcons{
        height: 250px;
        margin: 20px 0px 20px 0px;
    }
}

@media screen and (min-width: 1024px) {
    .ScopeIcons{
        height: auto;
        margin: 20px 0px 20px 0px;
    }
}

.betfairSectionScopeMainHeading{
    font-family: 'Calibre';
    font-size: 25px;
    font-weight: 600;
    line-height: 16px;
}

.betfairSectionScopeParagraph{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 0px;
}

.betfairLongTermPic{
display: none;
}


@media screen and (min-width: 1024px) {
    .betfairLongTermPic{
        width: 100%;
        height: auto;
        margin-top: 70px;
        display: block;
    }
}

.betfairLongTermPic1{
    width: 100%;
    height: auto;
    margin-top: 70px;
    display: block;
}

@media screen and (min-width: 1024px) {
    .betfairLongTermPic1{
        display: none;
    }
}


@media screen and (min-width: 1024px) {
    .rybaSectionHomeImg1{
        width: 50%;
        height: auto;
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.rybaSectionHomeImg1{
    width: 100%;
    height: auto;
    margin-top: 20px;
}

@media screen and (min-width: 1024px) {
    .rybaSectionHomeImg1{
        width: 50%;
        height: auto;
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.rybaSectionHomeMobileImg1{
    width: 100%;
    height: auto;
    margin-top: 20px;
}

@media screen and (min-width: 1024px) {
    .rybaSectionHomeMobileImg1{
        width: 50%;
        height: auto;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 10px;
    }
}

.apartFromWorkText1{
    font-size: 20px;
}

@media screen and (min-width: 768px) {
    .apartFromWorkText1{
        font-size: 25px;
        width: 80%;
    }
}

@media screen and (min-width: 1024px) {
    .apartFromWorkText1{
        font-size: 30px;
        width: 100%;
    }
}
