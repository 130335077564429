
.mainClassNeuroMobileWebsite{
    margin: auto;
    /* max-width: 1460px; */
}

.mainNeuroMobileContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F1F0FC;
}

.neuroMobileAppHeading{
    margin-top: 50px;
    color: #0a0a14;
    font-weight: 800;
    font-family: "Noe-Display";
    font-size: 22px;
    line-height: 30px;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .neuroMobileAppHeading{
        font-size: 40px;
    }
}

.neuroMobileAppParagraph{
    width: 300px;
    margin: 30px 0px 50px 0px;
    font-weight: 500;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .neuroMobileAppParagraph{
        margin: 30px 0px 90px 0px;
        width: 653px;
        font-size: 19px;
        font-weight: 500;
        cursor: pointer;
    }
}

.threeMobiles{
    height: auto;
    width: 90%;
}

@media screen and (min-width: 768px) {
    .threeMobiles{
        height: 300px;
        width: auto;
    }
}

@media screen and (min-width: 1024px) {
    .threeMobiles{
        height: 500px;
        width: auto;
    }
}

.neuroMobileSectionOverview{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 0px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionOverview{
        flex-direction: row;
        padding: 100px 0px;
    }
}

.neuroMobileSectionOverviewDivOne{
    margin: 0px 20px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionOverviewDivOne{
        max-width: 400px;
        margin: 0px 150px 0px 0px;
    }
}

.neuroMobileSectionOverviewDivOneReact{
    color: #7265E3;
    font-weight: 600;
}

.reactLogoNeuroMobile{
    height: 70px;
}

.neuroMobileSectionOverviewDivTwo{
    margin: 30px 20px 0px 20px;   
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionOverviewDivTwo{
        margin: 0px 0px;   
    }
}

.neuroMobileSectionTheProblem{
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
}

.neuroMobileSectionTheProblemMainDiv{
    padding: 50px 20px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionTheProblemMainDiv{
        max-width: 850px;
        padding: 70px 0px;
    }
}

.neuroMobileSectionResearch{
    display: flex;
    justify-content: center;
}

.neuroMobileSectionResearchMainDiv{
    padding: 50px 20px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionResearchMainDiv{
        max-width: 850px;
        padding: 70px 0px;
    }
}

.neuroMobileSectionProjectLearningMainDiv{
    padding: 50px 20px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionProjectLearningMainDiv{
        max-width: 750px;
        padding: 70px 0px;
    }
}

.neuroMobileSectionInsightsMainDiv{
    padding: 30px 20px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionInsightsMainDiv{
        max-width: 850px;
        padding: 50px 20px 0px 20px;
    }
}

.researchPicNeuroMobile{
    width: 100%;
    height: auto;
    margin-top: 20px;
}

.neuroMobileSectionInsights{
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.neuroMobileSectionUserProfiles{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.neuroMobileSectionInsightsDivsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 0px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionInsightsDivsContainer{
        display: flex;
        flex-direction: row;
        margin: 0px 80px;
    }
}

.resultsPicNeuroMobile{
    height: 40px;
    width: auto;
    margin-bottom: 15px;
}

.neuroMobileSectionInsightsSingleDiv{
    background-color: #ffffff;
    margin: 20px;
    padding: 30px;
    border: 1px solid rgba(114, 101, 227, 0.2);
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionInsightsSingleDiv{
        background-color: #ffffff;
        margin: 20px;
        padding: 30px;
        border: 1px solid rgba(114, 101, 227, 0.2);
        height: 300px;
    }
}

.neuroMobileSectionUserProfilesDivsContainerSpec{
    background-color: #ffffff;
    margin: 20px 0px;
    padding: 30px;
    border: 1px solid rgba(114, 101, 227, 0.2);
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionUserProfilesDivsContainerSpec{
 
        background-color: #ffffff;
        margin: 20px 0px;
        padding: 30px;
        border: 1px solid rgba(114, 101, 227, 0.2);
    }
}

.neuroMobileSectionUserProfilesDivsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 0px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionUserProfilesDivsContainer{
        margin: 0px 80px;
    }
}

.purpleHeadingNeuroMobile{
    font-family: 'Calibre';
    color: #7265E3;
    font-size: 24px;
    font-weight: 660;
}

.neuroMobileSectionResearchMainDivHeading{
    margin: 20px 0px;
    font-size: 25px;
    font-weight: 700;
    font-family: "Noe-Display";
    letter-spacing: -.5px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionResearchMainDivHeading{
        letter-spacing: 0px;
    }
}

.neuroMobileSectionConceptualizationSubDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.neuroMobileSectionConceptualizationHeading{
    font-family: 'Calibre';
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    margin-bottom: 0px;
}

.neuroMobileSectionMoreProjectsHeading{
    font-family: 'Calibre';
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    margin-bottom: 0px;
    text-align: center;
    padding: 20px;
}

.neuroMobileSectionConceptualizationParagraph{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 0px;
    text-align: center;
}

.neuroMobileSectionConceptualizationMainParagraph{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 20px;
}

.neuroMobileSectionProjectLearningMainParagraph{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 30px;
}

.neuroMobileSectionUsersProfilesParagraphs{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
}

.neuroMobileSectionUsersProfilesParagraphsBold{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
}

.neuroMobileSectionUsersProfilesParagraphCustom{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
}

.neuroMobileSectionUsersProfilesHeadings{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
}

.neuroMobileSectionOverviewHeading{
    font-family: 'Calibre';
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

.neuroMobileSectionOverviewParagraphs{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
}

.neuroMobileSectionOverviewCustomParagraph{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    max-width: 300px;
}

.neuroMobileSectionProjectLearningHeadings{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
}

.reactNeuroMobilesPicContainer{
    display: flex;
    align-items: flex-end;
    padding-bottom: 50px;
    margin-bottom: 0px;
    justify-content: center;
    min-width: 300px;
    min-height: 350px;
    /* background: #e9F5FB; */
    background: rgba(78, 206, 114, 0.1);
}

@media screen and (min-width: 768px) {
    .reactNeuroMobilesPicContainer{
        min-width: 350px;
        min-height: 370px;
        margin: 0px 20px;
    }
}

.reactNeuroPicContainer{
    display: flex;
    align-items: flex-end;
    padding-bottom: 50px;
    margin: 15px 0px 0px 0px;
    justify-content: center;
    min-width: 300px;
    min-height: 350px;
    background: #F1F0FC;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .reactNeuroPicContainer{
        min-width: 350px;
        min-height: 370px;
        margin: 0px 20px;
    }
}

.reactNeuroLaptopPicContainer{
    display: flex;
    align-items: flex-end;
    padding-bottom: 50px;
    margin: 15px 0px 0px 0px;
    justify-content: center;
    min-width: 300px;
    min-height: 350px;
    background: #F6F1EC;
}

@media screen and (min-width: 768px) {
    .reactNeuroLaptopPicContainer{
        min-width: 350px;
        min-height: 370px;
        margin: 0px 20px;
    }
}

.laptopPic1NeuroMobile{
    height: 200px;
    margin-left: 10px;
    transition: transform .8s;
    position: relative;
}

@media screen and (min-width: 768px) {
    .laptopPic1NeuroMobile{
        height: 230px;
    }
}

.laptopPic1NeuroMobile:hover{
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2); 
}

.Pic2NeuroMobile{
    height: 200px;
    transition: transform .8s;
    position: relative;
}

@media screen and (min-width: 768px) {
    .Pic2NeuroMobile{
        height: 230px;
    }
}

.Pic2NeuroMobile:hover{
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2); 
}

.mobilePic1NeuroMobile{
    height: 220px;
    margin-right: 30px;
    transition: transform .8s;
    position: relative;
}

@media screen and (min-width: 768px) {
    .mobilePic1NeuroMobile{
        height: 250px;
    }
}

.mobilePic1NeuroMobile:hover{
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2); 
}

.neuroMobileSectionTheSolutionVideo{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .neuroMobileSectionTheSolutionVideo{
        width: auto;
        height: 500px;
        margin-bottom: 0px;
    }
}

.neuroMobileSectionTheSolutionVideoAndParagraphDiv{
    display: flex;
    flex-direction: column;
    margin: 40px 0px 0px 0px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionTheSolutionVideoAndParagraphDiv{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 70px 0px 0px 0px;
    }
}

.neuroMobileSectionTheSolutionSubDiv{
max-width: 400px;
}

.neuroMobileSpacer{
    height: 100px;
}

.leftAero{
    width: 32px;
    height: 19px;
    margin-bottom: 4px;
    margin: 40px 0px 20px 50px;
}

@media screen and (min-width: 481px) {
    .leftAero{
        width: 42px;
        height: 27px;
        margin-bottom: 4px;
        margin: 40px 0px 20px 0px;
    }
}

.backAeroContainer{
    display: none;
}

@media screen and (min-width: 481px) {
    .backAeroContainer{
        background-color: #F1F0FC;
        display: flex;
        justify-content: center;
        padding: 0px 90px;
    }
}

.neuroMobileSectionInsightsMainContainer{
    max-width: 1460px;
    padding-bottom: 30px;
}

.backAeroContainerSub{
width: 100%;
max-width: 1280px;
}
