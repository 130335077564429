
.mainClassAboutWebsite{
    margin: auto;
    /* max-width: 1460px; */

}

.mainClassNewLaunchHero{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; 
    background: #EBE5F5;

}

@media screen and (min-width: 1024px) {
    .mainClassNewLaunchHero{
        background: #EBE5F5;

    }
}

.citySwapLogo{
    /* height: 30vh; */
    width: 60px;
    margin-top: 20px;
    margin-bottom: 40px;
}

@media screen and (min-width: 768px) {
    .citySwapLogo{
        /* height: 30vh; */
        width: 60px;
        margin-top: 0px;
        margin-bottom: 80px;
    }
}

.newLaunchMainHeading{
    font-size: 50px;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: 0px;
    max-width: 450px;
    /* text-align: left; */
}

.newLaunchSubHeading{
        font-size: 18px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        max-width: 480px;
        margin-top: 80px;
}

.subscribe{
    background-color: #664F94;
    font-size: 14px;
    font-weight: 700;
    color: white;
    padding: 9px 20px 10px 20px;
    border-radius: 0px 5px 5px 0px;
    height: 41px;
    margin-top: 0px;
    
}

@media screen and (min-width: 768px) {
    .subscribe{
        background-color: #664F94;
        font-size: 14px;
        font-weight: 700;
        color: white;
        padding: 9px 20px 10px 20px;
        border-radius: 0px 5px 5px 0px;
        height: 40px;
        margin-top: 2px;
        
    }
}

.subscribeForm{
    /* border: 1px solid #664F94;
    border-radius: 5px; */
}

.subscribeInput{
    height: 40px;
    border: 2px solid #664F94;
    border-radius: 5px 0px 0px 5px;
}
