.mainClassTopBarA{
    /* display: flex;
        justify-content: space-between;
        align-items: center; */
        /* padding: 0px 10px 0px 20px; */
        height: 60px;
}

@media screen and (min-width: 480px) {
    .mainClassTopBarA{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 50px;
        height: 90px;
        border-bottom: 1px solid rgba(114, 101, 227, 0.2);
    }
}

@media screen and (min-width: 1024px) {
    .mainClassTopBarA{
        padding: 0px 90px;
    }
}

.subClassTopBar{
    display: none;
}

@media screen and (min-width: 480px) {
  .subClassTopBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1280px;
}
}

.topbarRightContainerA{
    display: none;
}

@media screen and (min-width: 481px) {
    .topbarRightContainerA{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.helpTextA{
    color: black;
    margin: 10px 20px 10px 20px;
    font-family: 'Calibre';
    font-size: 16px;
    font-weight: 500;
}

.helpTextA:hover{
    color: #7265E3 !important;
    text-decoration: none;
}

.textDecorationA{
    text-decoration: none;
}

.textDecorationA:hover{
    text-decoration: none;
}

.topbarImageA{
    width: 40px;
    height: auto;
    margin: 20px;
}

@media screen and (min-width: 481px) {
    .topbarImageA{
      margin: 0px;
      width: 60px;
      height: auto;
    }
}




.dropbtn {
    height: 30px;
    margin: 16px;
  }
  
  .dropdown {
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (min-width: 480px) {
    .dropdown {
      display: none;
    }
  }

  
  .dropdown-contentA {
    display: none;
    top: 0;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    /* min-width: 212%; */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-contentA a {
    color: black;
    padding: 20px 56px 20px 15px;
    text-decoration: none;
    display: block;
    font-weight: 400;
  }
  
  .dropdown-contentA a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-contentA {display: block;}









  .header {
    /* background-color: #fff; */
    /* box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1); */
    position: fixed;
    width: 100%;
    z-index: 3;
  }
  
  .header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: #fff;
  }
  
  .header li a {
    display: block;
    padding: 20px 20px;
    border-right: 1px solid #f4f4f4;
    text-decoration: none;
  }
  
  .header li a:hover,
  .header .menu-btn:hover {
    background-color: #f4f4f4;
  }
  
  .header .logo {
    display: block;
    float: left;
    font-size: 2em;
    padding: 10px 20px;
    text-decoration: none;
  }
  
  /* menu */
  
  .header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .4s ease-out;
  }
  
  /* menu icon */
  
  .header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 30px 20px 18px 20px;
    position: relative;
    user-select: none;
  }
  
  .header .menu-icon .navicon {
    /* background: #333;  commented and below line added to remove middle line*/   
    background: transparent;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 24px;
  }
  
  .header .menu-icon .navicon:before,
  .header .menu-icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
  }
  
  .header .menu-icon .navicon:before {
    top: 4px;
  }
  
  .header .menu-icon .navicon:after {
    top: -4px;
  }
  
  /* menu btn */
  
  .header .menu-btn {
    display: none;
  }
  
  .header .menu-btn:checked ~ .menu {
    /* max-height: 240px; */
    /* Below 2 lines are written by Ali Sarib  */
    max-height: 100vh;
    height: 100vh;

  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }
  
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }
  
  /* 48em = 768px */
  
  @media (min-width: 48em) {
    .header li {
      float: left;
    }
    .header li a {
      padding: 20px 30px;
    }
    .header .menu {
      clear: none;
      float: right;
      max-height: none;
    }
    .header .menu-icon {
      display: none;
    }
  }

  @media screen and (min-width: 480px) {
    .header{
      display: none;
    }
  }