
.mainClassEducation{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: transparent;
}

@media screen and (min-width: 480px) {
    .mainClassEducation{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100vh;
        background: linear-gradient(55deg, #f9f9f9 50%, #890b38 50%, #890b38 60%, #f9f9f9 60%, #f9f9f9 70%, #890b38 70%, #890b38 80%, #f9f9f9 80%);

    }
  }

.titleEducationInstituteName{
    font-family: emoji;
    font-size: 19px;
    font-weight: 700;
    color: #2f89c5;
    margin-bottom: 2px;
}

@media screen and (min-width: 480px) {
    .titleEducationInstituteName{
        font-family: cursive;
    }
  }

.titleEducation{
    color: #890b38;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 15px;   
}

@media screen and (min-width: 480px) {
    .titleEducation{
        margin-bottom: 30px;   
    }
}

.imageEducation{
    height: 50vh;
}

@media screen and (min-width: 480px) {
    .imageEducation {
        height: 100vh;
    }
  }

.textContentEducation{
    display: flex;
    flex-direction: column;
    padding: 30px 20px 20px 20px;
    margin-left: 0px;
    margin-top: 0px;
    background: #f9f9f9;
}

@media screen and (min-width: 480px) {
    .textContentEducation{
        display: flex;
        flex-direction: column;
        margin-left: 100px;
        min-width: 35%;
        padding: 0px 0px;
        margin-top: 0px;
        background: transparent;
    }
}

.educationMainImageBackground{
    background: linear-gradient(55deg, #f9f9f9 50%, #890b38 50%, #890b38 60%, #f9f9f9 60%, #f9f9f9 70%, #890b38 70%, #890b38 80%, #f9f9f9 80%);
    width: 100%;
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 480px) {
    .educationMainImageBackground{
        background: transparent;
    }
}

.educationPlace{
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

.sectionEducationDegreeName{
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 2px;
    color: brown;
}

.sectionEducationDegreeYear{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
}

.instituteImageLogo{
    height: 80px;
    margin-right: 10px;
    margin-left: 10px;
}

.pgcImageLogo{
    height: 80px;
    width: 75px;
    margin-right: 5px;
}
