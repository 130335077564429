.neighbourMobileSectionResearchMidParagraph{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 0px;
    text-align: center;
    max-width: 600px;
}

.neighbourMobileSectionResearchMidHeading{
    font-family: 'Calibre';
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    margin-bottom: 0px;
    margin-top: 15px;
}

.neurooMobileSectionInsightsSingleDiv{
    background-color: #ffffff;
    margin: 20px;
    padding: 30px;
    border: 1px solid rgba(114, 101, 227, 0.2);
}

@media screen and (min-width: 1024px) {
    .neurooMobileSectionInsightsSingleDiv{
        background-color: #ffffff;
        margin: 20px;
        padding: 30px;
        border: 1px solid rgba(114, 101, 227, 0.2);
        height: 285px;
    }
}

.backAeroContainerNeighbourHoodMobile{
    display: none;
}

@media screen and (min-width: 481px) {
    .backAeroContainerNeighbourHoodMobile{
        background-color: rgba(78, 206, 114, 0.1);
        display: flex;
        justify-content: center;
        padding: 0px 90px;
    }
}

.mainNeuroMobileContainerNeighbourHoodMobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(78, 206, 114, 0.1);
}