
.mainClassApartFromWork{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;
    /* background: #F1F0FC; */
    /* height: 50vh; */

}

@media screen and (min-width: 480px) {
    .mainClassApartFromWork{
        display: flex;
        /* flex-direction: row; */
        justify-content: center;
        align-items: center;
        padding: 10px 0px;
        /* height: 60vh;
        min-height: 300px;
        max-height: 500px; */
    }
  }

  .apartFromWorkPicsContainer{
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 1024px) {
    .apartFromWorkPicsContainer{
        flex-direction: row;
      }
  }

.apartFromWorkPics{
   width: 100%;
}

@media screen and (min-width: 480px) {
    .apartFromWorkPics{
        width: 100%;
    }
}

.apartFromWorkText{
    font-size: 20px;
}

@media screen and (min-width: 768px) {
    .apartFromWorkText{
        font-size: 25px;
        width: 80%;
    }
}

@media screen and (min-width: 1024px) {
    .apartFromWorkText{
        font-size: 30px;
        width: 100%;
        text-align: center;
    }
}