
.neuroTabAppParagraph{
    width: 300px;
    margin: 30px 0px 50px 0px;
    font-weight: 500;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .neuroTabAppParagraph{
        margin: 30px 0px 90px 0px;
        width: 571px;
        font-size: 19px;
        font-weight: 500;
        cursor: pointer;
    }
}

.neuroMobileSectionInsightsDivsContainerNew{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 0px;
}

@media screen and (min-width: 1024px) {
    .neuroMobileSectionInsightsDivsContainerNew{
        display: flex;
        flex-direction: row;
        padding: 0px 0px 50px 0px;
        margin: 0px 80px ;
        max-width: 900px;
    }
}

.neuroMobileSectionConceptualizationParagraphTab{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 0px;
    max-width: 700px;
    text-align: center;
}

.neuroMobileSectionConceptualizationParagraphMaxWidth{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 0px;
    max-width: 500px;
    text-align: center;
}