
.mainClassAboutWebsite{
    margin: auto;
    /* max-width: 1460px; */

}

.mainClassAboutContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}

.aboutCoverImage{
/* width: 100%; */
margin-top: 10px;
height: 60vh;
padding: 20px;
/* object-fit: contain; */
}

@media screen and (min-width: 480px) {
    .aboutCoverImage{
        margin: 50px;
        padding: 0px;
        align-self: center;
        /* width: 50%; */
        height: 60%;
        }
}

.aboutImage{
        width: 100%;
        height: auto;
        margin: 20px 0px;
    }
    
    @media screen and (min-width: 480px) {
        .aboutImage{
            height: 350px;
            width: auto;
            margin: 20px 20px;
            }
    }
.aboutImagesContainer{
    padding: 20px
}

@media screen and (min-width: 480px) {
    .aboutImagesContainer{
        padding: 0px;
        }
}

.photographyMainContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.photographyMainHeading{
    margin-top: 50px;
        font-size: 25px;
        font-weight: 700;
        font-family: "Noe-Display";
        letter-spacing: -.5px;
}

@media screen and (min-width: 480px) {
    .photographyMainHeading{
        margin-top: 150px;
        }
}

.photographyMainParagraph{
    font-family: 'Calibre';
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 0px;
    padding: 0px 20px;
}

.aboutParagraphOneContainer{
    width: 100%;
    padding: 80px 20px;

}

@media screen and (min-width: 480px) {
    .aboutParagraphOneContainer{
        width: 70%;
        max-width: 1060px;
        padding: 80px 0px;
    }
}

.aboutParagraphOneHeading{
    font-weight: 600;
    font-family: 'Calibre';
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 0px;
}

.aboutParagraphOne{
    font-family: 'Calibre';
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 10px;
    padding: 0px 0px;
}

@media screen and (min-width: 480px) {
    .aboutParagraphOne{
        margin-bottom: 20px;
    }
}

.heroClassAboutContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}

@media screen and (min-width: 480px) {
    .heroClassAboutContainer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-height: 600px;
        padding: 60px 60px 0px 60px; 
    }
}

.aboutSectionScopeMainHeading{
    /* font-family: 'Calibre';
    font-size: 25px;
    font-weight: 600;
    line-height: 16px; */

font-family: Lora;
font-size: 32px;
font-weight: 600;
line-height: 45px;
letter-spacing: 0px;
text-align: left;

}

.betfairSectionScopeParagraph{
    /* font-family: 'Calibre';
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 0px; */

    font-family: 'Calibre';
    font-size: 19px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0px;
text-align: left;

}

.aboutSectionInsights{
    background: rgba(248, 251, 255, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

.blackHeadingAbout{
    font-family: 'Calibre';
    color: black;
    font-size: 20px;
    font-weight: 600;
}

.aboutSectionInsightsSingleDiv{
    /* background-color: #ffffff; */
    margin: 20px;
    padding: 30px;
    border: 1px solid rgba(114, 101, 227, 0.2);
    border-radius: 10px;
}

@media screen and (min-width: 1024px) {
    .aboutSectionInsightsSingleDiv{
        /* background-color: #ffffff; */
        margin: 20px;
        padding: 30px;
        border: 1px solid rgba(114, 101, 227, 0.2);
        height: 250px;
    }
}

