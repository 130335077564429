.mainClassTestContainer{
    margin: 40px 50px;
    max-width: 1100px;
}

.mainClassTest{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url("./images/background.jpg");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;

}

.testParagraph {
    width: 400px;
    margin: 20px 0px;
}

.listContainer {
    width: 300px;
    margin: 20px 0px;
}

.registerBox{
    background-color: white;
    margin-right: 20px;
    padding: 20px;
    border-radius: 10px;
}

.registerButton{
    background-color: #339aff;
    color: white;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    border: none;
    font-weight: 700;
}

.registerToday{
    background-color: black;
    color: white;
    padding: 10px 10px;
    border-radius: 5px;
}
