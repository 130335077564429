.hero-nav-container{

}

.mainClassWebsite{
    margin: auto;
    /* max-width: 1460px; */
}


@media screen and (min-width: 480px) {
    .hero-nav-container{
        /* background: linear-gradient(55deg, #f9f9f9 50%, #890b38 50%, #890b38 60%, #f9f9f9 60%, #f9f9f9 70%, #890b38 70%, #890b38 80%, #f9f9f9 80%); */
        width: 100%;
    }
}

.coverImage{
width: 100%;
object-fit: contain;
}

@media screen and (min-width: 480px) {
    .coverImage{
        width: 100%;
        height: 100vh;
        object-fit: cover;
        }
}

.coverImageQuiz{
    width: 100%;
    object-fit: contain;
    }
    
    @media screen and (min-width: 480px) {
        .coverImageQuiz{
            width: 100%;
            height: 100vh;
            object-fit: contain;
            }
}







/* New Logic to To Display moving background START */
.mainClassWebsiteHero {
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        /* background: rgba(238, 39, 55, 0.35); */
        background: rgba(31, 90, 165, 0.35);
        filter: blur(108.5px);
        width: 186px;
        height: 186px;
        position: absolute;
        top: -208px;
        left: 7%;
        z-index: -1;
        -webkit-animation: hero_before_rotate 10s infinite linear;
        -moz-animation: hero_before_rotate 10s infinite linear;
        animation: hero_before_rotate 10s infinite linear;

        @include breakpoint-down(md) {
            -webkit-animation: none;
            -moz-animation: none;
            animation: none;
            width: 120px;
            height: 120px;
            background: rgba(238, 39, 55, 0.3);
            filter: blur(75px);
            top: -128px;
            left: 56px;
        }
    }

    &::after {
        content: '';
        /* background: rgba(13, 34, 52, 0.35);  */
        background: rgba(248, 219, 0, 0.35);
        filter: blur(108.5px);
        width: 186px;
        height: 186px;
        position: absolute;
        top: 70px;
        right: -50px;
        z-index: -1;
        -webkit-animation: hero_after_rotate 10s infinite linear;
        -moz-animation: hero_after_rotate 10s infinite linear;
        animation: hero_after_rotate 10s infinite linear;

        @include breakpoint-down(md) {
            -webkit-animation: none;
            -moz-animation: none;
            animation: none;
            width: 120px;
            height: 120px;
            background: rgba(13, 34, 52, 0.25);
            filter: blur(60px);
            top: 234px;
            right: 24px;
        }
    }
}




@keyframes hero_before_rotate {
0% {
  top: -208px;
  left: 7%;
}

30% {
  top: calc(100% - 210px);
  left: 27%;
}

33% {
  top: calc(100% - 210px);
  left: 27%;
}

63% {
  top: 0px;
  left: calc(100% - 186px);
}

66% {
  top: 0px;
  left: calc(100% - 186px);
}

97% {
  top: -208px;
  left: 7%;
}

100% {
  top: -208px;
  left: 7%;
}
}

@keyframes hero_after_rotate {
0% {
  top: 70px;
  right: -50px;
}

30% {
  top: -200px;
  right: 25%;
}

33% {
  top: -200px;
  right: 25%;
}

63% {
  top: calc(100% - 206px);
  right: calc(100% - 186px);
}

66% {
  top: calc(100% - 206px);
  right: calc(100% - 186px);
}

97% {
  top: 70px;
  right: -50px;
}

100% {
  top: 70px;
  right: -50px;
}
}

/* New Logic to To Display moving background END */